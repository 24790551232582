<!-- Header tree pane -->
<div class="header">
    <span class="mat-h2">{{'Groups' | translate}}</span>
    <button mat-icon-button class="header-button" 
        [matTooltip]="'Expand all groups' | translate"
        (click)="expandTrees()">
        <mat-icon>
            unfold_more
        </mat-icon>
    </button>
    <button mat-icon-button class="header-button" 
        [matTooltip]="'Collapse all groups' | translate"
        (click)="collapseTrees()">
        <mat-icon>
            unfold_less
        </mat-icon>
    </button>
</div>

<mat-divider></mat-divider>

<div class="group-tree-scroll-box">
    <div class="group-tree-container">
        <!-- Trees -->
        <ng-container *ngFor="let uitree of this.uiTrees; odd as isOdd; last as isLast">
            <div class="group-tree" [class.odd]="isOdd">
                <div class="customer-header ellipses">
                    {{uitree.customerName}}
                    <button mat-icon-button 
                        [routerLink]="'/home/customer/'+uitree.customerId+'/keys'"
                        [matTooltip]="'Show customer settings' | translate" color="primary">
                        <mat-icon>tune</mat-icon>
                    </button>
                </div>

                <mat-tree [dataSource]="uitree.dataSource" [treeControl]="uitree.treeControl">
                    <!-- This is the tree node template for parent/EXPANDEABLE nodes -->
                    <mat-tree-node matTreeNodePadding [matTreeNodePaddingIndent]="30"
                        [routerLink]="'/home/group/'+node.id+'/'+currentSubPage.path"
                        *matTreeNodeDef="let node;when: hasChild" 
                        [class.selected]="node.id === this.selectedGroupId">
                        <button matTreeNodeToggle mat-icon-button>
                            <mat-icon>
                                {{uitree.treeControl.isExpanded(node) ? 'expand_more' :
                                'chevron_right'}}
                            </mat-icon>
                        </button>
                        <div class="node-text ellipses">{{node.name}}</div>
                        <button mat-icon-button 
                            (click)="openCreateGroupDialog(node)" 
                            *ngIf="this.editEnabled && node.permissions.createChildGroup"
                            [matTooltip]="'Add new group' | translate" 
                            color="primary">
                            <mat-icon class="action-icon">
                                add_circle_outline
                            </mat-icon>
                        </button>
                        <button mat-icon-button disabled></button>
                    </mat-tree-node>

                    <!-- This is the tree node template for child/LEAF nodes -->
                    <mat-tree-node matTreeNodePadding [matTreeNodePaddingIndent]="30"
                        [routerLink]="'/home/group/'+node.id+'/'+currentSubPage.path"
                        *matTreeNodeDef="let node" 
                        [class.selected]="node.id === this.selectedGroupId">
                        <div *ngIf="node.permissions?.openLock; else elseBlock">
                            <mat-icon class="node-icon">
                                    qr_code_scanner
                            </mat-icon>
                        </div>
                        <ng-template #elseBlock>
                            <button mat-icon-button [disabled]="true">
                                <mat-icon class="node-icon">
                                    chevron_right
                                </mat-icon>
                            </button>
                        </ng-template>

                        <div class="node-text ellipses">{{node.name}}</div>
                        <button mat-icon-button
                            [style.visibility]="this.editEnabled && node.permissions.createChildGroup?'visible':'hidden'"
                            [matTooltip]="'Add new group' | translate"  
                            (click)="openCreateGroupDialog(node)"
                            color="primary">
                            <mat-icon class="action-icon">
                                add_circle_outline
                            </mat-icon>
                        </button>
                        <button mat-icon-button 
                            class="delete-icon" 
                            [style.visibility]="this.editEnabled && node.permissions.delete?'visible':'hidden'"
                            color="warn" 
                            [matTooltip]="'Delete group' | translate"
                            (click)="$event.stopPropagation(); openDeleteGroupDialog(node)">
                            <mat-icon>delete_outline</mat-icon>
                        </button>
                    </mat-tree-node>
                </mat-tree>
            </div>
            <mat-divider *ngIf="!isLast"></mat-divider>
        </ng-container>

    </div>
</div>
